<template>
  <div>
    <v-row justify="start">
      <v-btn text color="primary" @click="navigateToHintergrundwissen">
        <v-icon>mdi-arrow-left</v-icon>
        Zurück zu: Hintergrundwissen
      </v-btn>
    </v-row>
    <br />

    <h2 id="diagnose">Diagnose</h2>
    <p>
      Nachdem Du Dir das Hintergrundwissen durchgelesen hast, baut nun der
      Diagnoseteil darauf auf:
    </p>

    <p>
      Auch wenn das Hintergrundwissen essentiell ist, ist es nicht weniger
      wichtig zu trainieren, dieses Wissen auf konkrete Dokumente von Kindern
      anwenden zu können. Vor allem ist es dabei zentral erfassen zu können, in
      welchen Bereichen des Operationsverständnis bereits gut ausgebildet ist
      und wo eventuell Probleme vorliegen. So kann eine Diagnose sowohl
      bereichsspezifisch gestaltet sein, wenn erste Indizien vorliegen, dass
      beispielsweise ein Problem beim Darstellungswechsel vorliegt. Es kann aber
      auch eine Standortbestimmung gestaltet sein, die alle drei Bereiche des
      Operationsverständnisses abdeckt. Dabei werden andere Herausforderungen an
      eine Lehrkraft gestellt. Bei Diagnosen im Allgemeinen sind sowohl
      Aufgabenauswahl als auch -analyse von immenser Bedeutung.
    </p>
    <p>
      Es ist im Unterricht häufig so, dass Kinder nicht nur bei einem
      Schwerpunkt Schwierigkeiten haben. Um nun also einige Dokumente
      detaillierter zu betrachten, geben die folgenden Videos Einblicke in drei
      unterschiedliche Dokumente von Kindern. Dazu überlege Dir zuerst, was
      schon gut gelingt. Anschließend soll geschaut werden, inwiefern
      Schwierigkeiten vorliegen und wie das mit weiteren Diagnoseaufgaben
      überprüft werden kann.
    </p>

    <p>
      Clarissa hat ein Malquartett aus vier Karten mit vier unterschiedlichen
      Darstellungen zu der Aufgabe 5 · 7 erstellt. Im folgenden Video wird ihr
      Dokument kurz analysiert.
    </p>

    <video
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')"
      @fullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
      "
      @webkitfullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
      "
      @mozfullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
      "
      @msfullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
      "
      @ended="videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')"
      @pause="videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')"
      @seeking="videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')"
      @enterpictureinpicture="
        videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
      "
      @leavepictureinpicture="
        videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
      "
    >
      <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
      <source
        src="@/assets/ovi/Diagnose/Diagnose Clarissa_neu.mp4"
        type="video/mp4"
      />
    </video>

    <p>Es folgt ein weiteres Malquartett von Ninon.</p>

    <video
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')"
      @fullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
      "
      @webkitfullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
      "
      @mozfullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
      "
      @msfullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
      "
      @ended="videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')"
      @pause="videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')"
      @seeking="videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')"
      @enterpictureinpicture="
        videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
      "
      @leavepictureinpicture="
        videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
      "
    >
      <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
      <source
        src="@/assets/ovi/Diagnose/Diagnose Ninon_neu.mp4"
        type="video/mp4"
      />
    </video>
    <br />

    <p>
      Die Unterschiede zwischen Ninons und Clarissas Malquartett sind deutlich
      erkennbar, auch wenn sie auf den ersten Blick an ähnlichen Stellen liegen.
      Beispielsweise deutet bereits die Beschreibung der Malaufgabe als „7
      5-mal“ und „3 Sechser“ auf eine unterschiedliche Nutzung
      bedeutungsbezogener Sprache hin. So können die Kinder beim
      Vorstellungsaufbau unterstützt werden, indem möglichst bedeutungsvolle
      Sprache genutzt wird – Sechser gibt somit an, wie viele Elemente in einer
      Gruppe vorhanden sind.
    </p>
    <p>
      Im nächsten Dokument hat John die Aufgabe 12 · 4 möglichst geschickt zu
      rechnen und dies mit Plättchen zu veranschaulichen.
    </p>

    <video
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')"
      @fullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
      "
      @webkitfullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
      "
      @mozfullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
      "
      @msfullscreenchange="
        videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
      "
      @ended="videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')"
      @pause="videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')"
      @seeking="videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')"
      @enterpictureinpicture="
        videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
      "
      @leavepictureinpicture="
        videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
      "
    >
      <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
      <source
        src="@/assets/ovi/Diagnose/Diagnose John_neu.mp4"
        type="video/mp4"
      />
    </video>
    <br />

    <p>
      Es zeigt sich: Sowohl die Kompetenzen, die Fehler, als auch die Ursachen
      für diese Fehler können sehr unterschiedlich sein. Insgesamt ist es
      wichtig, dass die Lehrkraft die verschiedenen Facetten des umfassenden
      Operationsverständnisses kennt und diese aktiv in den Unterricht einbaut,
      sodass die Schülerinnen und Schüler möglichst zahlreiche und umfassende
      Möglichkeiten zum Ausbau dieser (Teil-)Kompetenzen erhalten. Es kann
      beispielsweise Kinder geben, die die Malaufgaben bereits automatisiert
      haben und die Rechnungen richtig notieren, doch keine eigene
      Rechengeschichte angeben können, die Rückschlüsse auf eine
      Grundvorstellung gibt. Um dies zu diagnostizieren – und dann anschließend
      zu fördern oder zu fordern – ist es essentiell, dass auch solche Aufgaben,
      die nach der Grundvorstellung fragen, gestellt werden, um dies zum einen
      aufzudecken und dem zum anderen entgegenzuwirken.
    </p>
    <p>
      Dabei ist die Aufgabenauswahl, die die Lehrkraft trifft ein zentraler
      Punkt, um alle Facetten abzudecken. Dazu werden im Folgenden je drei
      Aufgaben vorgestellt, die die drei Bereiche des Operationsverständnisses –
      Grundvorstellungen, Darstellungswechsel und Aufgabenbeziehungen –
      hauptaugenmerklich ansprechen. Dabei wird vor allem der
      Darstellungswechsel in fast allen Aufgaben zumindest implizit
      angesprochen, da die Kinder beispielsweise zwischen Rechengeschichte und
      Mathesprache – sprich Aufgabe – wechseln. Eine solche Aufgabe kann
      eingesetzt werden, um zu überprüfen, inwiefern die Schülerinnen und
      Schüler über die Grundvorstellung in der Rechengeschichte verfügen, aber
      dazu müssen sie eben auch zwangsläufig zwischen zwei Darstellungsweisen
      wechseln. Die folgenden beiden fokussieren aber eher die
      Grundvorstellungen:
    </p>

    <v-row justify="center" class="standAloneCardImages">
      <v-col :cols="standAloneCardImagesFormat">
        <AppExpandableImage
          :src="require('@/assets/ovi/Diagnose/diagnose_niklas.png')"
          contain
          parentId="OVI"
          elementId="Diagnose/diagnose_niklas.png"
        />
      </v-col>
      <v-col :cols="standAloneCardImagesFormat">
        <AppExpandableImage
          :src="require('@/assets/ovi/Diagnose/diagnose_claas.png')"
          contain
          parentId="OVI"
          elementId="Diagnose/diagnose_claas.png"
        />
      </v-col>
    </v-row>

    <p>
      Aber auch Aufgaben, die Kinder dazu anregen Malaufgaben in ihrer Umwelt zu
      fotografieren fokussieren meistens die Grundvorstellungen. Es wird dadurch
      deutlich, welche Grundvorstellungen die Kinder bereits aufgebaut haben.
      Sicherlich vor allem die Grundvorstellung des Zusammenfassens lässt sich
      häufig in der Umwelt finden und auf Bildern festhalten. Dennoch ist eine
      solche Aufgabe als Überprüfung aller Grundvorstellungen sinnvoll.
    </p>

    <v-row justify="center" class="standAloneCardImages">
      <v-col :cols="standAloneCardImagesFormat">
        <AppExpandableImage
          :src="require('@/assets/ovi/Diagnose/diagnose_fotokartei.png')"
          contain
          parentId="OVI"
          elementId="Diagnose/diagnose_fotokartei.png"
        />
      </v-col>
    </v-row>

    <p>
      Steht der Darstellungswechsel mehr im Fokus, sehen die Aufgaben anders
      aus. Die beiden nachfolgenden Aufgaben bilden unterschiedliche
      Darstellungsformen ab, den Zahlenstrahl und die Plättchendarstellung.
      Dabei sind solche Darstellungen sowohl Lernstoff als auch Lernhilfe.
      Lernstoff sind sie insofern, als dass sie nicht selbsterklärend sind und
      gemeinsam mit den Kindern erarbeitet werden müssen.
    </p>

    <v-row justify="center" class="standAloneCardImages">
      <v-col :cols="standAloneCardImagesFormat">
        <AppExpandableImage
          :src="require('@/assets/ovi/Diagnose/diagnose_zahlenstrahl.png')"
          contain
          parentId="OVI"
          elementId="Diagnose/diagnose_zahlenstrahl.png"
        />
      </v-col>
      <v-col :cols="standAloneCardImagesFormat">
        <AppExpandableImage
          :src="require('@/assets/ovi/Diagnose/diagnose_3mal5.png')"
          contain
          parentId="OVI"
          elementId="Diagnose/diagnose_3mal5.png"
        />
      </v-col>
    </v-row>

    <p>
      Auch diese Aufgabe zeigt, dass hier der Wechsel der Darstellungsform im
      Fokus steht und viele unterschiedliche Darstellungen zu einer Aufgabe
      miteinander verknüpft werden sollen. Sicherlich wird vor allem auch im
      Aufgabenteil a) die Grundvorstellung deutlich, die das Kind nutzt, aber
      durch die Verknüpfung mehrerer Darstellungsformen zu derselben Aufgabe,
      steht eben eher der Darstellungswechsel im Fokus.
    </p>

    <v-row justify="center" class="standAloneCardImages">
      <v-col :cols="standAloneCardImagesFormat">
        <AppExpandableImage
          :src="require('@/assets/ovi/Diagnose/diagnose_2mal7.png')"
          contain
          parentId="OVI"
          elementId="Diagnose/diagnose_2mal7.png"
        />
      </v-col>
    </v-row>

    <p>
      Aber auch die Aufgabenbeziehungen können durch Aufgaben fokussiert und
      somit unterstützt werden. Die Aufgaben regen Kinder dazu an, Muster und
      Strukturen zu erkennen, zu beschreiben und diese zu begründen, um die
      Beziehung dann nutzen zu können. Dies ist zum beziehungsreichen Üben der
      Multiplikation zentral.
    </p>

    <v-row justify="center">
      <v-col :cols="standAloneCardImagesFormat">
        <AppExpandableImage
          :src="require('@/assets/ovi/Diagnose/diagnose_4mal6.png')"
          contain
          parentId="OVI"
          elementId="Diagnose/diagnose_4mal6.png"
        />
      </v-col>
      <v-col :cols="standAloneCardImagesFormat">
        <AppExpandableImage
          :src="require('@/assets/ovi/Diagnose/diagnose_2mal9.png')"
          contain
          parentId="OVI"
          elementId="Diagnose/diagnose_2mal9.png"
        />
      </v-col>
    </v-row>

    <v-row justify="center" class="standAloneCardImages">
      <v-col :cols="standAloneCardImagesFormat">
        <AppExpandableImage
          :src="require('@/assets/ovi/Diagnose/diagnose_suna.png')"
          contain
          parentId="OVI"
          elementId="Diagnose/diagnose_suna.png"
        />
      </v-col>
    </v-row>

    <p>
      Nur mit dem Wissen was zu einen umfassenden Operationsverständnis zählt,
      kann eine themengerechte Diagnose und Förderung erstellt werden.
    </p>

    <v-row justify="start">
      <v-btn text color="primary" @click="navigateToHintergrundwissen">
        <v-icon>mdi-arrow-left</v-icon>
        Zurück zu: Hintergrundwissen
      </v-btn>
    </v-row>
    <br />

    <Survey
      assignedSite="ovi"
      broderTopic="ov"
      :topics="[
        'Grundvorstellungen',
        'Darstellungswechsel beim Operationsverständnis',
        'Aufgabenbeziehungen',
        'Operationsverständnis allgemein',
      ]"
      :alreadySend="
        $store.state.user.completedSurveys
          ? $store.state.user.completedSurveys.includes('ovi')
          : false
      "
      :skipLearningStyle="$store.state.user.completedSurveys.length > 0"
    />
  </div>
</template>

<script>
import Survey from "@/components/faledia/Survey";
import AppHelper from "@/common/AppHelper";
import AppExpandableImage from "@/common/AppExpandableImage";
export default {
  beforeCreate: function() {
    AppHelper.isUserAllowedRedirectIfNot("ovi");
  },
  components: {
    Survey,
    AppExpandableImage,
  },
  methods: {
    navigateToHintergrundwissen: function() {
      this.$router.push({ name: "ovi" });
    },
  },
  computed: {
    standAloneCardImagesFormat() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
        return 6;
      } else {
        return 5;
      }
    },
  },
};
</script>

<style scoped>
video,
.standAloneAudio {
  margin-top: 20px;
  margin-bottom: 20px;
}

.standAloneCardImages {
  margin-bottom: 12px;
}
</style>
